import {
  faImage as fasImage,
  faFilePdf as fasFilePdf,
  faFileExcel as fasFileExcel,
  faFileWord as fasFileWord,
  faFile as fasFile,
  faFileAlt as fasFileAlt,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faImage as falImage,
  faFilePdf as falFilePdf,
  faFileExcel as falFileExcel,
  faFileWord as falFileWord,
  faFile as falFile,
  faFileAlt as falFileAlt,
} from "@fortawesome/pro-light-svg-icons";
import { assembleVisibleFieldsWithColumns } from "../charts/ActiveTable/ActiveTableViews/useActiveTableViewManager";
import produce from "immer";
import { orderBy } from "lodash-es";

/**
 * @typedef {import("@fortawesome/fontawesome-common-types").IconDefinition} IconDefinition
 */

export function getActiveTableAndViewUuidFromState(state) {
  const currentTable = state.activeTable.currentTable;
  const currentViewUuid = state.activeTable.currentViewUuid;
  const queryFields = state.activeTable.queryFields;

  return {
    currentTable,
    currentViewUuid,
    queryFields,
  };
}

/**
 * @param currentActiveTableViews
 * @param currentActiveTableColumns
 * @param currentViewUuid
 * @param queryFields
 * @returns {string[]}
 */
export function getActiveTableFields(
  currentActiveTableViews,
  currentActiveTableColumns,
  currentViewUuid,
  queryFields
) {
  const views = currentActiveTableViews ?? [];

  const view =
    views.find((v) => v.uuid === currentViewUuid) ??
    orderBy(views, [(v) => !!v.displaySettings?.isDefaultView], ["desc"])[0];

  const columnNameByUuid = Object.fromEntries(
    currentActiveTableColumns.map((column) => [column.uuid, column.name])
  );
  const queryFieldColumnNameByUuid = Object.fromEntries(
    (queryFields ?? []).map((queryField) => [
      queryField.dataSourceFieldUuid,
      queryField.name,
    ])
  );

  return (
    view
      ? view.visibleFields
          .filter((v) => !v.aggregationType)
          .map(
            (v) =>
              columnNameByUuid[v.colId] ?? queryFieldColumnNameByUuid[v.colId]
          )
      : currentActiveTableColumns.map((c) => c.name)
  ).filter((v) => typeof v === "string");
}

export function getActiveTableFieldsFromState(state) {
  const { currentTable, currentViewUuid, queryFields } =
    getActiveTableAndViewUuidFromState(state);
  return getActiveTableFields(
    currentTable.views,
    currentTable.columns,
    currentViewUuid,
    queryFields
  );
}

/**
 * @param columnType
 * @returns {{accept: ?string, apiType: ?string, solid_icon: IconDefinition, light_icon: IconDefinition}}
 */
export function getActiveTableFileTypeConfig(columnType) {
  return (
    ACTIVE_TABLE_FILE_TYPE_MAP[columnType] ?? {
      solid_icon: fasFile,
      light_icon: falFile,
      accept: undefined,
      apiType: undefined,
    }
  );
}

export function getSpecificFileTypeByFilename(filename) {
  if (!filename) {
    return;
  }
  const extension = String(filename).split(".").at(-1);

  const types = ["api-image", "api-pdf", "api-excel", "api-word"];
  for (const type of types) {
    if (ACTIVE_TABLE_FILE_TYPE_MAP[type]?.accept.includes(extension)) {
      return ACTIVE_TABLE_FILE_TYPE_MAP[type];
    }
  }
}

const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
const pdfExtensions = ["pdf"];
const excelExtensions = ["xlsx", "xls"];
const wordExtensions = ["docx", "doc"];
const documentExtensions = [
  ...pdfExtensions,
  ...excelExtensions,
  ...wordExtensions,
];
export const ACTIVE_TABLE_FILE_TYPE_MAP = {
  "api-image": {
    solid_icon: fasImage,
    light_icon: falImage,
    accept: toAcceptString(imageExtensions),
    apiType: "api-image",
  },
  "api-pdf": {
    solid_icon: fasFilePdf,
    light_icon: falFilePdf,
    accept: toAcceptString(pdfExtensions),
    apiType: "api-pdf",
  },
  "api-excel": {
    solid_icon: fasFileExcel,
    light_icon: falFileExcel,
    accept: toAcceptString(excelExtensions),
    apiType: "api-excel",
  },
  "api-word": {
    solid_icon: fasFileWord,
    light_icon: falFileWord,
    accept: toAcceptString(wordExtensions),
    apiType: "api-word",
  },
  "api-document": {
    solid_icon: fasFile,
    light_icon: falFile,
    accept: toAcceptString(documentExtensions),
    apiType: "api-document",
  },
  "api-document-or-image": {
    solid_icon: fasFileAlt,
    light_icon: falFileAlt,
    accept: toAcceptString([...documentExtensions, ...imageExtensions]),
    apiType: "api-document-or-image",
  },
};

export function isActiveTableColumnTypeFile(columnType) {
  return !!ACTIVE_TABLE_FILE_TYPE_MAP[columnType];
}

function toAcceptString(extensions) {
  return extensions ? extensions.map((v) => `.${v}`).join(",") : undefined;
}

export function getBulkUploadPayloadFromUpdateEvent(
  event,
  visibleFields,
  columns,
  queryFields
) {
  const colDef = event.colDef;
  const values = {
    [colDef.field]: event.data[colDef.field],
  };

  const keys = {};

  for (const { visibleField, column } of assembleVisibleFieldsWithColumns(
    visibleFields,
    columns,
    queryFields
  )) {
    if (visibleField.aggregationType) {
      continue;
    }
    keys[column.name] =
      column.name === colDef.field
        ? // Use the old value, because the data object contains
          // the row object with the new value already applied.
          event.oldValue
        : event.data[column.name];
  }

  return { keys, values };
}

export function performBulkUpdateOnLocalData(existingRows, data) {
  const { keys, values } = data;

  return produce(existingRows, (draft) => {
    for (const row of draft) {
      let matching = true;
      for (const [key, value] of Object.entries(keys)) {
        if (value !== row[key]) {
          // Not a matching row.
          matching = false;
          break;
        }
      }
      if (matching) {
        Object.assign(row, values);
      }
    }
    return draft;
  });
}
