import React, { useEffect, useState, useCallback } from "react";
import { format, parseISO } from "date-fns";
import isEqual from "react-fast-compare";
import ActiveTableFileEditor from "./Grid/ActiveTableFileEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiImage from "../TableView/Elements/ApiImage";
import { cloneDeep, orderBy, pick, merge, omit } from "lodash-es";
import { isDate } from "../../utils/formatters/dateFormatter";
import { mergeColumnsAndQuery } from "./activeTableHelpers";
import usePrevious from "../../utils/usePrevious";
import styled from "@emotion/styled";
import {
  getActiveTableFileTypeConfig,
  getSpecificFileTypeByFilename,
  isActiveTableColumnTypeFile,
} from "../../utils/activeTable";
import { colors } from "./constants";
import Flex from "../../UI/Flex/Flex";
import { useActiveTableFileDownload } from "./Grid/common";
import formatter from "../../utils/formatters/formatter";
import produce from "immer";
import ActiveTableDropdown from "./Grid/ActiveTableDropdown";
import { activeTableDateFormatString } from "../../utils/constants/constants";

/**
 * @typedef {import('@ag-grid-community/core').ColDef} ColDef
 */

const Container = styled.div`
  height: 100%;
  & > div {
    height: 100% !important;
    width: 80px !important;
    margin: 0 auto;
  }
`;

export const noFileOpacity = 0.4;

export function FileRenderer(value) {
  const columnType = value.colDef.type;
  const fileTypeConfig = getActiveTableFileTypeConfig(columnType);

  const field = value.colDef.field;
  const fileValue = value.data[field];

  const fileIsProcessed = !!fileValue && typeof fileValue === "string";
  const isImage = columnType === "api-image";

  const icon = (getSpecificFileTypeByFilename(fileValue) ?? fileTypeConfig)
    .solid_icon;

  const { downloadIcon } = useActiveTableFileDownload(fileValue);

  return (
    <Container>
      {isImage && fileIsProcessed ? (
        <ApiImage value={fileValue} blockZoom contain />
      ) : (
        <Flex
          style={{
            fontSize: 32,
            color: colors.fileIcon,
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
          title={fileIsProcessed ? "" : "No File Uploaded."}
        >
          <FontAwesomeIcon
            icon={icon}
            color={colors.fileIcon}
            opacity={fileIsProcessed ? 1 : noFileOpacity}
          />

          {!isImage && downloadIcon}
        </Flex>
      )}
    </Container>
  );
}

export default function useActiveTableSettings(
  tableConfig,
  user = { groups: [] },
  queryFields,
  dataSourceUuid,
  handleRowChangeRef
) {
  // To always call the latest-rendered version of the callback.
  const handleRowChange = useCallback(
    (...args) => handleRowChangeRef.current(...args),
    [handleRowChangeRef]
  );
  const [previousActiveTableRoot, setPreviousActiveTableRoot] = useState({});
  const [activeTableRoot, setActiveTableRoot] = useState({});

  // Table Columns for Configs
  const [columns, setColumns] = useState([]);

  // Last timeTableConfig was updated
  const [prevConfig, setPrevConfig] = useState(null);

  // Last time table config was updated, translated versions
  const [prevTranslatedConfig, setPrevTranslatedConfig] = useState(null);
  // Errors object similar Laravel's API error response format.
  // Keys represent field names, and the values are arrays of error messages.
  const [columnErrorsObject, setColumnErrorsObject] = useState({});

  function setColumnError(key, value) {
    setColumnErrorsObject((columnErrorsObject) =>
      produce(columnErrorsObject, (draft = {}) => {
        if (value) {
          if (Array.isArray(value)) {
            draft[key] = value;
          } else {
            if (!columnErrorsObject[key]) {
              draft[key] = [];
            }
            // Trick to make sure the error object does not get mutated
            // unnecessarily if it would result in no changes.
            draft[key].splice(0, Infinity, value);
          }
        } else {
          delete draft[key];
        }
        return draft;
      })
    );
  }

  const defaultRow = columns.reduce((acc, curr) => {
    return { ...acc, [curr.field]: curr.type === "boolean" ? false : "" };
  }, {});

  // for join mode we need to get actual query fields which are come after active table
  // so wee need to update columns one more time
  const prevQueryFields = usePrevious(queryFields);

  const shouldUpdate =
    !isEqual(tableConfig, prevConfig) || !isEqual(queryFields, prevQueryFields);

  // update full configs on load and after save response is returned
  useEffect(() => {
    if (shouldUpdate) {
      const mergedColumns = mergeColumnsAndQuery(
        tableConfig.columns,
        queryFields
      );
      const initialColumns = removeIoSystemColumns(mergedColumns);
      const orderedInitialColumns = orderBy(initialColumns, "sortPriority");

      const nextColumnsConverted = orderedInitialColumns
        .map(applyAccessRules)
        .map((column) => convertColumn(column, handleRowChange, queryFields));
      setColumns(nextColumnsConverted);
      setPrevConfig(tableConfig);
      const { columns, datasourceForPermissions, ...previousActiveTableRoot } =
        tableConfig;
      previousActiveTableRoot.permissionsFromDataSourceUuid =
        datasourceForPermissions?.uuid;
      setPreviousActiveTableRoot(previousActiveTableRoot);
      const activeTableRoot = { ...previousActiveTableRoot };

      if (dataSourceUuid && !activeTableRoot.permissionsFromDataSourceUuid) {
        // Let's associate the data source with the active table for permissions
        activeTableRoot.permissionsFromDataSourceUuid = dataSourceUuid;
      }
      setActiveTableRoot(activeTableRoot);
      setPrevTranslatedConfig({
        ...tableConfig,
        columns: nextColumnsConverted,
      });
    }
    function applyAccessRules(column) {
      if (user.role === "tenant_owner") return column;
      const accessUuids = column.displaySettings?.accessGroups;
      if (!accessUuids?.length) return column;
      if (!column.isEditable) return column;
      const hasAccess = user.groups.find((uuid) => accessUuids.includes(uuid));
      return { ...column, isEditable: !!hasAccess };
    }
  }, [
    dataSourceUuid,
    handleRowChange,
    queryFields,
    shouldUpdate,
    tableConfig,
    user.groups,
    user.role,
  ]);

  const updateColumn = (index, newColumn) => {
    const newColumns = columns.map((col, i) => {
      const { queryOnly, ...rest } = newColumn;
      return i === index
        ? convertColumn(rest, handleRowChange, queryFields)
        : col;
    });
    setColumns(newColumns);
    return convertToApiColumns(newColumns);
  };

  const apiColumns = convertToApiColumns(columns);

  const addColumn = (name, type) => {
    const nextColumns = [
      ...columns,
      {
        colId: "temp",
        field: name || "",
        type: type || "string",
        editable: true,
        displaySettings: { activeOnly: true },
        cellEditorParams: {},
      },
    ];
    return setColumns(nextColumns);
  };

  const setAllLocked = () => {
    const nextColumns = columns.map((col) => ({ ...col, editable: false }));
    return setColumns(nextColumns);
  };

  const getColumn = (uuid) => {
    const column = columns.find((c) => c.colId === uuid);
    if (!column) {
      console.error(`No column with UUID '${uuid}' found`);
      return;
    }
    return column;
  };

  function produceColumnByUuid(uuid, produceCallback) {
    setColumns((columns) => {
      const column = columns.find((c) => c.colId === uuid);
      if (!column) {
        console.error(`No column with UUID '${uuid}' found`);
        return columns;
      }
      const updatedColumn = produce(column, produceCallback);
      return updatedColumn && updatedColumn !== column
        ? columns.map((existingColumn) =>
            existingColumn.colId === uuid ? updatedColumn : existingColumn
          )
        : columns;
    });
  }

  const addOption = (uuid, value) => {
    const column = getColumn(uuid);

    column.cellEditorParams.values = [...column.cellEditorParams.values, value];

    const nextColumns = columns.map((col) =>
      col.uuid === uuid ? column : col
    );

    return setColumns(nextColumns);
  };

  function setOptions(uuid, rawOptions) {
    return setColumns((columns) => {
      const column = cloneDeep(columns.find((c) => c.colId === uuid));
      let options;

      if (typeof rawOptions[0] === "object") {
        options = rawOptions.map((option) => option.value);
      } else {
        options = rawOptions;
      }

      column.cellEditorParams.values = options;

      const nextColumns = columns.map((col) =>
        col.colId === uuid ? column : col
      );
      return nextColumns;
    });
  }

  function setAccessGroups(uuid, nextGroups) {
    const nextColumns = columns.map((col) =>
      col.colId === uuid
        ? {
            ...col,
            cellEditorParams: {
              ...(col.cellEditorParams ?? {}),
              accessGroups: nextGroups,
            },
          }
        : col
    );

    return setColumns(nextColumns);
  }

  function setDisplayFormatOverride(uuid, value) {
    const nextColumns = columns.map((col) =>
      col.colId === uuid
        ? {
            ...col,
            cellEditorParams: {
              ...(col.cellEditorParams ?? {}),
              displayFromatOverride: value,
            },
          }
        : col
    );

    return setColumns(nextColumns);
  }
  const nextConfig = { ...activeTableRoot, columns: apiColumns };

  const isActiveTableRootDirty = !isEqual(
    activeTableRoot,
    previousActiveTableRoot
  );
  const areColumnsDirty = !!(
    nextConfig.columns.length !== 0 &&
    !isEqual(columns.map(ignoreVisible), prevTranslatedConfig.columns)
  );
  const isDirty = areColumnsDirty || isActiveTableRootDirty;

  function ignoreVisible(column) {
    const { visible, ...rest } = column;
    return rest;
  }

  function updateConfig(config) {
    setPrevConfig(config);
  }

  // Settings Field name validation
  function validateFieldName(colDef) {
    const { field, colId } = colDef;
    const regex = /^\w+( +\w+)*$/;
    let message = null;

    // do not include new field and editing field
    const fields = columns.filter(
      (col) => col.colId !== "temp" && col.colId !== colId
    );

    if (!field) {
      message = "Column name cannot be empty.";
    } else if (field.length < 2) {
      message = "Column name must have at least 2 characters.";
    } else if (!regex.test(field)) {
      message = `Column name cannot contain only spaces, end or start with space or non alphanumeric symbols`;
    } else if (fields.some((col) => col.field === field)) {
      message = "That column name already exists.";
    }

    setColumnError("name", message);
  }

  return {
    nextConfig,
    columns,
    apiColumns,
    updateColumn,
    setColumns,
    defaultRow,
    addColumn,
    addOption,
    setOptions,
    produceColumnByUuid,
    setAllLocked,
    setAccessGroups,
    isDirty,
    updateConfig, // setUpdateConfig
    prevConfig,
    validateFieldName,
    convertToApiColumns,
    columnErrorsObject,
    setColumnError,
    setDisplayFormatOverride,
    dataSourceForPermissionsSet:
      !!previousActiveTableRoot.permissionsFromDataSourceUuid,
  };
}

export function convertToApiColumns(cols) {
  return cols.map((col, i) => {
    const {
      field,
      editable,
      colId,
      cellEditor,
      cellEditorParams,
      cellClass,
      displayName,
      ...rest
    } = col;
    let type = col.type;
    let displaySettings = {
      accessGroups: cellEditorParams?.accessGroups || [],
      activeOnly: !!col.displaySettings?.activeOnly,
      displayFromatOverride: cellEditorParams?.displayFromatOverride,
      ...pick(cellEditorParams, displaySettingsProperties),
    };

    let valueOptions = [];
    if (type === "select") {
      type = "string";
      displaySettings.editMode = "select";
      valueOptions = (col.cellEditorParams.values ?? []).map((v) => ({
        value: v,
      }));
    }

    const {
      headerName,
      joinedOverride,
      queryOnly,
      headerClass,
      cellStyle,
      cellRenderer,
      ...remaining
    } = rest;

    const ret = {
      ...remaining,
      name: col.field,
      isEditable: col.editable,
      uuid: col.colId !== "temp" ? col.colId : undefined,
      type,
      displaySettings,
      valueOptions,
      sortPriority: i,
    };

    if (ret.width) {
      ret.displaySettings.width = ret.width;
    } else {
      delete ret.displaySettings.width;
    }

    if (col.queryOnly) {
      ret.etlOnly = true;
      ret.isEditable = false;
      ret.isNullable = false;
      ret.type = null;
      ret.precision = null;
      ret.defaultValue = null;
      ret.valueOptions = null;
    }

    return ret;
  });
}

export const systemColumns = [
  "uuid",
  "io_created_at",
  "io_updated_at",
  "IOUUID",
  "IOLastUpdatedBy",
  "IOLastUpdated",
  "IOCreated",
  "IOCreatedBy",
  "IOEffectiveStart",
  "IOEffectiveEnd",
];

const loadFromDataProperties = [
  "loadFromData",
  "loadFromQueryUuid",
  "loadFromFieldName",
];

const displaySettingsOtherProperties = ["displayName"];

const displaySettingsProperties = [
  ...loadFromDataProperties,
  ...displaySettingsOtherProperties,
];

function removeIoSystemColumns(columns) {
  return columns
    .filter((c) => !systemColumns.find((s) => s === c.name))
    .map((c) => ({ ...c, field: c.name }));
}

export const convertColumn = (column, handleRowChange, queryFields) => {
  const displaySettings = cloneDeep(column.displaySettings ?? {});
  if (displaySettings.displayFromatOverride === "default") {
    delete displaySettings.displayFromatOverride;
  }
  const accessGroups =
    displaySettings.accessGroups ?? column.cellEditorParams?.accessGroups ?? [];

  const displayFromatOverride =
    displaySettings.displayFromatOverride ??
    column.cellEditorParams?.displayFromatOverride;

  const isEditEnabled = !!(column.isEditable || column.editable);

  const { mapping } =
    (queryFields ?? []).find(
      (field) => field.name === column.name || field.name === column.field
    ) ?? {};
  const displayName = mapping?.displayName ?? column.field;

  const values = column.cellEditorParams?.values?.length
    ? column.cellEditorParams.values
    : column.valueOptions?.length
    ? column.valueOptions.map((v) => v.value)
    : [];

  /** @type ColDef */
  const setting = {
    field: column.field,
    editable: isEditEnabled, // To work for FE and API configs
    type:
      column?.displaySettings?.editMode === "select" ? "select" : column.type,
    colId: column.colId || column.uuid,
    headerName: displayName,
    cellEditorParams: {
      accessGroups,
      displayFromatOverride,
      handleChange: handleRowChange,
      values: values.filter((v) => v !== "None..."),
      ...pick(displaySettings, displaySettingsProperties),
    },
    displaySettings,
  };
  if (column.queryOnly || column.etlOnly) setting.queryOnly = true;
  if (column.joinedOverride) setting.joinedOverride = true;
  if (displaySettings.width) {
    setting.width = displaySettings.width;
  }

  return setting;
};

/**
 * Converts the Active Table Column form format to the actual
 * ColDef format used by Active Grid.
 *
 * @param col
 * @param loadValues
 * @param loadValuesSearch
 * @returns {{[p: string]: *}|*}
 */
export function amendColumnForActiveGrid(col, loadValues, loadValuesSearch) {
  // We need to get rid of all properties that ag-react doesn't recognize.
  const column = omit(cloneDeep(col), [
    "joinedOverride",
    "queryOnly",
    "displaySettings",
  ]);

  column.enableCellChangeFlash = true;

  if (!column.width) {
    // This is needed so then when switching a column's column width back to
    // "Default", the column's width would appear to change to default
    // width in Active Grid.
    column.width = 200;
  }

  const cellEditorParams = column.cellEditorParams ?? {};
  column.headerName = `${column.editable ? "✏️ " : ""}${
    cellEditorParams.displayName || col.headerName
  }`;

  if (cellEditorParams.displayFromatOverride === "default") {
    delete cellEditorParams.displayFromatOverride;
  }

  column.type = cellEditorParams.displayFromatOverride ?? column.type;

  if (isActiveTableColumnTypeFile(column.type)) {
    // When sorting by file columns, we don't care about the actual filenames,
    // only about whether there is a file or not.
    column.comparator = fileComparator;
    if (cellEditorParams.displayFromatOverride) {
      // If the file type was a display format override, ensure it's
      // not editable, otherwise the API will potentially reject files
      // due to validation.
      column.editable = false;
    }
  }

  const displayFromatOverride = cellEditorParams.displayFromatOverride;

  const type = displayFromatOverride ?? column.type;

  //column.cellEditorParams.values = new Set("");

  switch (type) {
    case "api-image":
    case "api-pdf":
    case "api-excel":
    case "api-word":
    case "api-document":
    case "api-document-or-image":
      return merge(column, getCommonFileRendererProperties());
    case "date":
      return {
        ...column,
        cellEditor: "agDateStringCellEditor",
        valueGetter(params) {
          const value = params.data[params.colDef.field];
          return value === "" ? null : value;
        },
        valueFormatter: (params) => {
          if (params.value && isDate(params.value)) {
            return format(parseISO(params.value), activeTableDateFormatString);
          }
          return params.value;
        },
      };
    case "select":
      return {
        ...column,
        cellEditor: cellEditorParams.loadFromData
          ? ActiveTableDropdown
          : "agSelectCellEditor",
        ...(cellEditorParams.loadFromData
          ? { cellClass: "no-overflow-hidden" }
          : {}),
        cellEditorParams: {
          ...cellEditorParams,
          ...pick(cellEditorParams, displaySettingsProperties),
          ...(cellEditorParams.loadFromData
            ? {
                loadValues,
                loadValuesSearch,
              }
            : {}),
          values: [...new Set(["None...", ...cellEditorParams.values])],
        },
      };
    case "percent":
    case "percent-whole":
    case "percent-whole-2":
    case "percent-round":
    case "percent-tenth":
    case "percent-hundredth":
    case "currency":
    case "currency-whole":
    case "currency-tenth":
    case "integer":
    case "decimal":
    case "decimal-tenth":
    case "date-active-table":
    case "datetime":
      const precision = type === "percent" ? 0 : undefined;
      return {
        ...column,
        valueFormatter: (params) => {
          if (!params?.value && params?.value !== 0) {
            return "--";
          }
          return formatter(params.value ?? "", type, precision);
        },
        headerClass: "ag-right-aligned-header",
        cellStyle: { textAlign: "right" },
      };

    case "text":
      return { ...column, cellEditor: "agTextCellEditor" };
    case "string":
      return {
        ...column,
        cellEditor: "agTextCellEditor",
      };
    case "boolean":
      return {
        ...column,
        cellEditor: "agCheckboxCellEditor",
        cellRenderer: "agCheckboxCellRenderer",
        cellStyle: { display: "flex", justifyContent: "center" },
      };
    default:
      return column;
  }

  function getCommonFileRendererProperties() {
    return {
      cellEditorPopup: true,
      cellEditorPopupPosition: "over",
      headerClass: "ag-center-aligned-header",
      cellEditor: ActiveTableFileEditor,
      cellRenderer: FileRenderer,
    };
  }
}

export function fileComparator(fileValueA, fileValueB) {
  const valueA = fileValueA !== null;
  const valueB = fileValueB !== null;
  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
}
